import React from 'react';

import styles from './TextInput.module.scss';

export default React.forwardRef(({
  type,
  label,
  value,
  setValue,
  errorMessage,
  autocomplete,
  className = ''
}: {
  type: 'email' | 'password';
  label: string;
  value: string;
  setValue: (text: string) => void;
  errorMessage: string;
  autocomplete?: string;
  className?: string;
}, ref?: React.Ref<HTMLInputElement>) => {
  return (
    <div className={`${styles.Field} ${className}`}>
      <label htmlFor={type}>{label}</label>
      <input
        id={type}
        name={type}
        type={type}
        autoComplete={autocomplete}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={(e) => {
          // Trim spaces, Firefox does not let you submit the form otherwise
          e.target.value = e.target.value.trim();
        }}
        ref={ref}
      />
      {errorMessage && <p className={styles.Error}>{errorMessage}</p>}
    </div>
  );
});
